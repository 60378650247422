<template>
    <div>
        <section id="overview-categorys">
            <div v-for="category in getCategoriesRelevance" :key=category.id>
                <div class="overview-categorys">
                    <b>{{ category.inquiry }}</b>
                    <ProgressBar :segments_total=category.maxAnswers.length :segments_1=getCategoryImplications_1(category)
                        :segments_2=getCategoryImplications_2(category)>
                    </ProgressBar>
                    <div>
                        {{ category.answers.length, }} / {{ category.maxAnswers.length }}
                    </div>
                    <div>
                        <b-icon :icon="category.isStarred ? 'star-fill' : 'star'" :style="{ color: '#F2AF4A' }"></b-icon>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>

import NavSidebar from "../../../components/Nav_Sidebar.vue"
import SortSidebar from "../../../components/Sort_Sidebar.vue"
import NavHeader from "../../../components/Nav_Header.vue"
import HeaderCard from "../../../components/Header_Card.vue"
import CollapseProfileCategory from "../../../components/Collapse_Profile_Category.vue"
import ProgressBar from "../../../components/ProgressBar.vue"

export default {
    name: 'profilePDF',
    props: {
        isOverview: Boolean,
    },
    components: {
        NavHeader,
        HeaderCard,
        NavSidebar,
        SortSidebar,
        CollapseProfileCategory,
        ProgressBar
    },
    computed: {
        getAllCategories() {
            return this.$store.getters['survey/getAllCategories']
        },
        getCategoriesRelevance() {
            return this.$store.getters['survey/getCategoriesRelevance']
        },
        getShowProfileInOverview() {
            return this.$store.getters['survey/getShowProfileInOverview']
        },

    },
    methods: {
        getCategoryImplicationsLength(category) {
            return this.$store.getters['survey/getCategoryImplicationsLength'](category)
        },
        getCategoryImplications_1(category) {
            return this.$store.getters['survey/getCategoryImplications_1'](category)
        },
        getCategoryImplications_2(category) {
            return this.$store.getters['survey/getCategoryImplications_2'](category)
        },

    },
    data() {
        return {
            description: "Das ELSI-Profil bietet einen Überblick über 10 ELSI_Kategorien und ihre Relevanz für Ihr eigenes Forschungsvorhaben. Diese wird anhand Ihrer Antworten ermittelt. Umso voller der Relevanzindikator einer ELSI-Kategorie, desto wichtiger ist es, dass Sie sich mit der Thematik beschäftigen und keine Implikationen übersehen.",
            info: "Sehen Sie sich an, welche Ihrer Antworten wahrscheinliche, mögliche und unwahrscheinliche Implikationen mit sich bringen. Informieren Sie sich zu den für Sie wichtigsten ELSI-Kategorien.",
            clickedCat: false,
            currentQuestion: {},
            boldText: '10 ELSI_Kategorien',
            maxLength: 0,
            segment1: 0,
            segment2: 0,
            projectOverviewText: "Ermitteln Sie anhand von Fragen, welche Eigenschaften Ihres Forschungsprojektes  bestimmte Implikationen für relevante ELSI-Kategorien mit sich bringen und wie wahr-scheinlich diese sind.",
        }
    }
}

</script>

<style lang="scss" scoped>
section#overview-categorys {
    display: flex;
    width: 100%;
    flex-direction: column;

    .overview-categorys {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 35fr 35fr 10fr 8fr;
        grid-column-gap: 1.5rem;
        height: 5rem;
        align-content: center;
    }
}
</style>